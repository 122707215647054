import React, { ReactNode, useState } from 'react';
import {
    Alert,
    AppBar,
    Container,
    Drawer,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Snackbar,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { useDispatch, useSelector } from 'react-redux';
import { unregisterCompany } from './store/qrSlice';
import { RootState } from './store';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_VERSION } from './versions';

const reloadPage = () => {
    window.location.reload();
};

export const PageWrapper = ({
    children,
    bgColor = '#ffffff',
}: {
    children: ReactNode;
    bgColor?: string;
}) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const companyInfo = useSelector((state: RootState) => state.qr.company);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const toggleMenu = (state?: boolean) => {
        if (typeof state !== 'undefined') {
            setMenuIsOpen(state);
        } else {
            setMenuIsOpen(!menuIsOpen);
        }
    };

    const handleUnregister = () => {
        dispatch(unregisterCompany());
        toggleMenu(false);
        setShowAlert(true);
        setTimeout(() => {
            if (location.pathname === '/') {
                reloadPage();
            } else {
                navigate('/');
            }
        }, 1000);
    };

    return (
        <Container
            disableGutters
            sx={{
                height: '100vh',
                width: '100vw',
                padding: 0,
                margin: 0,
                backgroundColor: bgColor,
            }}
        >
            <AppBar
                sx={(theme) => ({
                    height: '80px',
                    backgroundColor: '#FFFFFF',
                    backgroundImage: 'url("logo/logo.png")',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '30%',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    [theme.breakpoints.up('sm')]: {
                        backgroundSize: '20%',
                    },
                })}
            >
                {companyInfo?.id && (
                    <>
                        <IconButton
                            sx={{ p: '10px' }}
                            onClick={() => setMenuIsOpen(!menuIsOpen)}
                        >
                            <SettingsIcon sx={{ fontSize: '30px' }} />
                        </IconButton>
                        <Drawer
                            anchor="top"
                            open={menuIsOpen}
                            onClose={() => toggleMenu(false)}
                            sx={{ marginTop: '80px' }}
                        >
                            <MenuList>
                                <MenuItem
                                    sx={(theme) => ({
                                        height: '80px',
                                        backgroundColor: '#FFFFFF',
                                        backgroundImage: 'url("logo.png")',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '30%',
                                        backgroundPosition: 'center',
                                        [theme.breakpoints.up('sm')]: {
                                            backgroundSize: '20%',
                                        },
                                    })}
                                />
                                <MenuItem onClick={handleUnregister}>
                                    <ListItemIcon>
                                        <LinkOffIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Unlink from current account
                                    </ListItemText>
                                </MenuItem>
                            </MenuList>
                        </Drawer>
                    </>
                )}
            </AppBar>
            <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={() => setShowAlert(false)}
            >
                <Alert
                    sx={{ width: '100%' }}
                    severity="success"
                    onClose={() => setShowAlert(false)}
                >
                    Successfully unlinked account
                </Alert>
            </Snackbar>
            {children}
            {process.env.REACT_APP_ENVIRONMENT === 'dev' && (
                <span
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        color: '#FFF',
                        padding: '0 5px 0',
                    }}
                >
                    {`v${APP_VERSION}-beta`}
                </span>
            )}
        </Container>
    );
};
