import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import qrReducer from './qrSlice';

const reducers = combineReducers({
    qr: qrReducer,
});

const persistConfig = {
    key: 'root',
    storage,
};

export default persistReducer(persistConfig, reducers);
