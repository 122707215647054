import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Card,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
} from '@mui/material';
import { PageWrapper } from '../PageWrapper';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useInvoice } from '../context/InvoiceContext';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { getCompanyType } from '../companyTypes';
import { API } from '../api';
import { addCode } from '../store/qrSlice';

const InvoiceDetails: React.FC = () => {
    const navigate = useNavigate();
    const {
        amount,
        maxAmount,
        setAmount,
        image,
        setImage,
        amountRequired,
        receiptRequired,
        voucher,
    } = useInvoice();
    const companyInfo = useSelector((state: RootState) => state.qr.company);
    const companyToken = companyInfo?.token;
    const companyType = getCompanyType(companyInfo?.type);
    const dispatch = useDispatch();

    const handleButtonClick = () => {
        navigate('/invoice-photo');
    };

    const handleBackClick = () => {
        setAmount('0');
        setImage(null);
        navigate('/');
    };

    const hasError = useMemo(() => {
        if (isNaN(Number(amount))) {
            return true;
        }
        if (maxAmount && amount && Number(amount) > Number(maxAmount)) {
            return true;
        }
        return false;
    }, [amount, maxAmount]);
    const isButtonDisabled =
        (receiptRequired && !image) ||
        (amountRequired && !amount) ||
        Number(amount) < 0 ||
        hasError;

    const handleActivateButton = () => {
        const url = `${API}${companyType}-vouchers/${voucher?.id}`;

        axios
            .patch(
                url,
                {
                    securityToken: voucher?.token,
                    ...(amountRequired ? { usedAmount: amount } : {}),
                    ...(receiptRequired
                        ? {
                              photo: image?.replaceAll(
                                  'data:image/png;base64,',
                                  ''
                              ),
                          }
                        : {}),
                },
                {
                    headers: {
                        Authorization: companyToken,
                    },
                }
            )
            .then(() => {
                dispatch(addCode(voucher?.id));
                navigate('/success');
            })
            .catch(() => {
                navigate('/error');
            })
            .finally(() => {
                setImage(null);
                setAmount('0');
            });
    };

    return (
        <PageWrapper>
            <p style={{ marginTop: '100px', marginLeft: '10px' }}>
                <Button
                    variant="text"
                    startIcon={<ChevronLeftIcon />}
                    onClick={handleBackClick}
                >
                    Back
                </Button>
            </p>
            <Card
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    position: 'relative',
                    color: '#FFFFFF',
                    gap: '20px',
                    p: '0 10px',
                    minHeight: '100px',
                    m: '10px 10px 0 10px',
                }}
            >
                <FormControl fullWidth sx={{ m: 1 }} error={hasError}>
                    <InputLabel htmlFor="outlined-adornment-amount">
                        Receipt Amount
                    </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        endAdornment={
                            <InputAdornment position="end">
                                &euro;
                            </InputAdornment>
                        }
                        type="text"
                        label="Receipt Amount"
                        disabled={!amountRequired}
                        value={amount}
                        inputProps={{
                            pattern: '/^d*.?d*$',
                            placeholder: '0.00',
                        }}
                        onKeyDown={(e) => {
                            const key = e.key;
                            if (
                                /^\d$/.test(key) ||
                                key === '.' ||
                                key === 'Backspace' ||
                                key === 'Delete'
                            ) {
                                // Allow default behavior for allowed keys
                                return true;
                            } else {
                                // Prevent default for other keys
                                e.preventDefault();
                                return false;
                            }
                        }}
                        onChange={(e) => {
                            const value = e.target.value;
                            setAmount(value);
                        }}
                    />
                    {hasError && (
                        <FormHelperText>
                            Must be less or equal to {maxAmount}
                        </FormHelperText>
                    )}
                </FormControl>
                {receiptRequired && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            color: '#FFF',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton onClick={handleButtonClick}>
                            <AddAPhotoIcon
                                sx={{ width: '1em', height: '1em' }}
                            />
                        </IconButton>
                        <Typography
                            variant="body2"
                            color="primary"
                            sx={{
                                textTransform: 'uppercase',
                                fontSize: '.5em',
                            }}
                        >
                            Take a photo of the receipt
                        </Typography>
                    </div>
                )}
            </Card>

            {receiptRequired && image && (
                <Card
                    sx={{
                        m: '10px',
                    }}
                >
                    <img
                        src={image}
                        alt="Captured"
                        style={{
                            display: 'block',
                            width: '100%',
                            height: 'auto',
                            margin: 'auto',
                        }}
                    />
                </Card>
            )}
            <div
                style={{
                    padding: '80px 10px 0 10px',
                }}
            >
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleActivateButton}
                    sx={{
                        backgroundColor: '#FFFFFF',
                    }}
                    disabled={isButtonDisabled}
                >
                    <Typography
                        sx={{ textTransform: 'uppercase', color: '#000' }}
                    >
                        Activate Voucher
                    </Typography>
                </Button>
            </div>
        </PageWrapper>
    );
};

export default InvoiceDetails;
