import { Alert } from '@mui/material';
import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
    error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: null,
    };

    public static getDerivedStateFromError(error: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <>
                    {this.props.children}
                    <Alert variant="filled" severity="error">
                        {this.state.error?.message || 'Something went wrong'}
                    </Alert>
                </>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
