import React from 'react';

export const ViewFinder = ({ color = 'rgba(0, 0, 0, 0.5)' }) => (
    <svg
        width="50px"
        viewBox="0 0 100 100"
        style={{
            top: 0,
            left: 0,
            zIndex: 1,
            boxSizing: 'border-box',
            border: '100px solid transparent',
            position: 'absolute',
            width: '100%',
            height: '100%',
        }}
    >
        <path fill="none" d="M13,0 L0,0 L0,13" stroke={color} strokeWidth="5" />
        <path
            fill="none"
            d="M0,87 L0,100 L13,100"
            stroke={color}
            strokeWidth="5"
        />
        <path
            fill="none"
            d="M87,100 L100,100 L100,87"
            stroke={color}
            strokeWidth="5"
        />
        <path
            fill="none"
            d="M100,13 L100,0 87,0"
            stroke={color}
            strokeWidth="5"
        />
    </svg>
);
