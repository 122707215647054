import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeCompanyInfo } from '../types';

type QRState = {
    company: TypeCompanyInfo | null;
    codes: any[];
};

const initialState: QRState = {
    company: null,
    codes: [],
};

export const qrSlice = createSlice({
    name: 'qr',
    initialState,
    reducers: {
        addCode: (state, action: PayloadAction<any>) => {
            // Add new scanned code to the list
            state.codes.push(action.payload);
        },
        registerCompany: (state, action: PayloadAction<TypeCompanyInfo>) => {
            state.company = action.payload;
        },
        unregisterCompany: (state) => {
            state.company = null;
        },
    },
});

// Action creators are generated for each case reducer function
export const { addCode, registerCompany, unregisterCompany } = qrSlice.actions;

export default qrSlice.reducer;
