import React, { createContext, useContext, useState } from 'react';
import { VoucherParams } from '../types';

interface InvoiceContextProps {
    amount: string | null;
    maxAmount: number | null;
    image: string | null;
    amountRequired: boolean;
    receiptRequired: boolean;
    setAmount: React.Dispatch<React.SetStateAction<string>>;
    setMaxAmount: React.Dispatch<React.SetStateAction<number | null>>;
    setImage: React.Dispatch<React.SetStateAction<string | null>>;
    setAmountRequired: React.Dispatch<React.SetStateAction<boolean>>;
    setReceiptRequired: React.Dispatch<React.SetStateAction<boolean>>;
    voucher: VoucherParams | null;
    setVoucher: React.Dispatch<React.SetStateAction<VoucherParams | null>>;
}

const InvoiceContext = createContext<InvoiceContextProps | undefined>(
    undefined
);

export const InvoiceProvider: React.FC = ({ children }) => {
    const [amount, setAmount] = useState<string>('0');
    const [image, setImage] = useState<string | null>(null);
    const [voucher, setVoucher] = useState<VoucherParams | null>(null);
    const [amountRequired, setAmountRequired] = useState<boolean>(true);
    const [receiptRequired, setReceiptRequired] = useState<boolean>(true);
    const [maxAmount, setMaxAmount] = useState<number | null>(null);

    return (
        <InvoiceContext.Provider
            value={{
                amount,
                setAmount,
                maxAmount,
                setMaxAmount,
                image,
                setImage,
                amountRequired,
                setAmountRequired,
                receiptRequired,
                setReceiptRequired,
                voucher,
                setVoucher,
            }}
        >
            {children}
        </InvoiceContext.Provider>
    );
};

export const useInvoice = (): InvoiceContextProps => {
    const context = useContext(InvoiceContext);
    if (!context) {
        throw new Error('useInvoice must be used within an InvoiceProvider');
    }
    return context;
};
