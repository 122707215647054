import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ErrorBoundary from './ErrorBoundaryMain';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { CssBaseline } from '@mui/material';
import { App } from './App';

const root = document.getElementById('root') as HTMLElement;
render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ErrorBoundary>
                <CssBaseline />
                <App />
            </ErrorBoundary>
        </PersistGate>
    </Provider>,
    root
);

serviceWorkerRegistration.register();
