import { ApiCompanyType } from './types';

export const COMPANY_ICONS = {
    taxi: 'local-taxi',
    hotel: 'local-hotel',
    retailer: 'store',
};

export const COMPANY_TYPES = {
    taxi: 'taxi',
    hotel: 'hotel',
    retailer: 'refreshment',
};

export const getCompanyType = (apiType?: ApiCompanyType) => {
    if (!apiType) {
        return 'refreshment';
    }

    return COMPANY_TYPES[apiType];
};
