import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useInvoice } from '../context/InvoiceContext';
import { IconButton, Typography } from '@mui/material';
import { ArrowCircleRightRounded, ReplayRounded } from '@mui/icons-material';
import { PageWrapper } from '../PageWrapper';

export const PhotoScreen = () => {
    const { image, setImage } = useInvoice();
    const navigate = useNavigate();

    return (
        <PageWrapper bgColor="#000000">
            <div
                style={{
                    width: '100vw',
                    height: '100vh',
                    marginTop: '80px',
                }}
            >
                <img
                    src={image ?? ''}
                    alt="Captured"
                    style={{
                        display: 'block',
                        maxWidth: '100vw',
                        maxHeight: '100vh',
                        width: 'auto',
                        height: 'auto',
                        margin: 'auto',
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        bottom: '150px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            color: '#FFF',
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                setImage(null);
                                navigate('/invoice-photo');
                            }}
                            sx={{ color: '#FFF' }}
                        >
                            <ReplayRounded
                                sx={{ width: '2.5em', height: '2.5em' }}
                            />
                        </IconButton>
                        <Typography
                            variant="body2"
                            sx={{ alignSelf: 'center' }}
                        >
                            Retake
                        </Typography>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            color: '#FFF',
                        }}
                    >
                        <IconButton
                            onClick={(e) => {
                                navigate('/invoice-details');
                            }}
                            sx={{ color: '#FFF' }}
                        >
                            <ArrowCircleRightRounded
                                sx={{ width: '2.5em', height: '2.5em' }}
                            />
                        </IconButton>
                        <Typography
                            variant="body2"
                            sx={{ alignSelf: 'center' }}
                        >
                            Use Photo
                        </Typography>
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};
