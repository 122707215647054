import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import StoreIcon from '@mui/icons-material/Store';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { PageWrapper } from '../PageWrapper';

const SuccessScreen: React.FC = () => {
    const navigate = useNavigate();
    const companyInfo = useSelector((state: RootState) => state.qr.company);

    const handleButtonClick = () => {
        navigate('/');
    };

    return (
        <PageWrapper>
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#63b151',
                    position: 'relative',
                    color: '#FFFFFF',
                    gap: '20px',
                    p: '0 10px',
                }}
            >
                <CheckIcon sx={{ fontSize: '100px' }} />
                <Typography variant="body1" sx={{ textTransform: 'uppercase' }}>
                    Successfully Activated
                </Typography>
                <Typography variant="body2">
                    The voucher is successfully activated
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                    }}
                >
                    <StoreIcon sx={{ fontSize: '20px' }} />{' '}
                    <Typography variant="body2">{companyInfo?.name}</Typography>
                </div>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleButtonClick}
                    sx={{
                        backgroundColor: '#FFFFFF',
                        margin: '20px 0',
                    }}
                >
                    <Typography
                        sx={{ textTransform: 'uppercase', color: '#000' }}
                    >
                        Scan Next Voucher
                    </Typography>
                </Button>
            </Box>
        </PageWrapper>
    );
};

export default SuccessScreen;
