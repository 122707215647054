import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { QRScan } from './qrscan';
import SuccessScreen from './feedback/SuccessScreen';
import SuccessLink from './feedback/SuccessLink';
import NoLinkFail from './feedback/NoLinkFail';
import GenericFail from './feedback/GenericFail';
import FailScreen from './feedback/FailScreen';
import InvoiceDetails from './invoice/InvoiceDetails';
import TakeInvoicePhoto from './invoice/TakeInvoicePhoto';
import { InvoiceProvider } from './context/InvoiceContext';
import { PhotoScreen } from './invoice/PhotoScreen';

const routes = [
    {
        path: '/',
        element: <QRScan />,
    },
    {
        path: '/invoice-details',
        element: <InvoiceDetails />,
    },
    {
        path: '/invoice-photo',
        element: <TakeInvoicePhoto />,
    },
    {
        path: '/photo-confirmation',
        element: <PhotoScreen />,
    },
    {
        path: '/success',
        element: <SuccessScreen />,
    },
    {
        path: '/linked',
        element: <SuccessLink />,
    },
    {
        path: '/not-linked',
        element: <NoLinkFail />,
    },
    {
        path: '/fail',
        element: <FailScreen />,
    },
    {
        path: '/error',
        element: <GenericFail />,
    },
];

const router = createBrowserRouter(routes);

export const App = () => {
    return (
        <InvoiceProvider>
            <RouterProvider router={router} />
        </InvoiceProvider>
    );
};
