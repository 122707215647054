import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { OnResultFunction, QrReader } from 'react-qr-reader';
import { ViewFinder } from '../ViewFinder';
import { API } from '../api';
import { RootState } from '../store';
import { addCode, registerCompany } from '../store/qrSlice';
import { ApiVoucher, TypeCompanyInfo, VoucherParams } from '../types';
import { getCompanyType } from '../companyTypes';
import { PageWrapper } from '../PageWrapper';
import { useInvoice } from '../context/InvoiceContext';

function getVoucherParams(value: string) {
    const splittedUrl = value.split('/');
    const idWithToken = splittedUrl.pop()?.split('?token=');

    return { id: idWithToken?.[0], token: idWithToken?.[1] };
}

export const QRScan = () => {
    const [finderColor, setFinderColor] =
        useState<string>('rgba(0, 0, 0, 0.5)');
    const dispatch = useDispatch();
    const companyInfo = useSelector((state: RootState) => state.qr.company);
    const scannedVouchers = useSelector((state: RootState) => state.qr.codes);
    const navigate = useNavigate();
    const { setVoucher, setAmountRequired, setReceiptRequired, setMaxAmount } =
        useInvoice();
    const isRequesting = useRef<boolean>(false);
    const cooldownTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const onCompanyLinkScan = (data: any) => {
        let linkData = null;
        try {
            linkData = JSON.parse(data);
        } catch {
            setFinderColor('rgba(255, 0, 0, 0.5)');
            return false;
        }

        axios
            .post(`${API}users/${linkData.type}/link`, {
                key: linkData.key,
            })
            .then((response) => {
                const scannedData: TypeCompanyInfo = {
                    id: response.data.id,
                    name: response.data.name,
                    type: response.data.companyType,
                    token: response.headers.authorization,
                };

                dispatch(registerCompany(scannedData));
                navigate('/linked');
            })
            .catch((err) => {
                setFinderColor('rgba(255, 0, 0, 0.5)');
                navigate('/error');
            })
            .finally(() => {
                isRequesting.current = false;
                addCooldown();
            });

        return true;
    };

    const processVoucher = (
        response: AxiosResponse,
        voucherParams: VoucherParams,
        companyToken: string,
        url: string
    ) => {
        const resData = response.data;
        const voucher: ApiVoucher = resData.voucher;
        const isAmountRequired = !!resData.amountRequired;
        const isReceiptRequired = !!resData.receiptRequired;

        if (voucher) {
            if (isAmountRequired || isReceiptRequired) {
                setMaxAmount(voucher.value || null);
                setAmountRequired(isAmountRequired);
                setReceiptRequired(isReceiptRequired);
                setVoucher(voucherParams);
                navigate('/invoice-details');
            } else {
                axios
                    .patch(
                        url,
                        {
                            securityToken: voucherParams.token,
                            usedAmount: voucher.value,
                        },
                        {
                            headers: {
                                Authorization: companyToken,
                            },
                        }
                    )
                    .then(() => {
                        dispatch(addCode(voucher.id));
                        navigate('/success');
                    })
                    .catch((err) => {
                        setFinderColor('rgba(255, 0, 0, 0.5)');
                        navigate('/error');
                    })
                    .finally(() => {
                        isRequesting.current = false;
                        addCooldown();
                    });
            }
        } else if (resData.status === 'Already activated') {
            setFinderColor('rgba(255, 0, 0, 0.5)');
            navigate('/fail');
        }
    };

    const onVoucherScan = (value: string) => {
        const voucherParams = getVoucherParams(value);
        const companyToken = companyInfo?.token;
        const companyType = getCompanyType(companyInfo?.type);
        const { id, token } = voucherParams;
        const regex = /^\d+$/;

        if (id && !regex.test(id)) {
            console.warn('This is not a valid voucher id', id);
            navigate('/error');
            return;
        }

        if (scannedVouchers.indexOf(Number(id)) === -1) {
            const url = `${API}${companyType}-vouchers/${id}`;
            axios
                .post(
                    url,
                    {
                        securityToken: token,
                    },
                    {
                        headers: {
                            Authorization: companyToken,
                        },
                    }
                )
                .then((response) => {
                    processVoucher(
                        response,
                        voucherParams,
                        companyToken || '',
                        url
                    );
                })
                .catch((err) => {
                    setFinderColor('rgba(255, 0, 0, 0.5)');
                    navigate('/error');
                });
        } else {
            navigate('/fail');
        }
    };

    const addCooldown = () => {
        cooldownTimeoutRef.current = setTimeout(() => {
            cooldownTimeoutRef.current = null;
        }, 1000);
    };

    const handleResult: OnResultFunction = (result) => {
        if (cooldownTimeoutRef.current) return;

        if (!isRequesting.current && result) {
            isRequesting.current = true;

            if (companyInfo?.name) {
                onVoucherScan(result.getText());
            } else {
                !onCompanyLinkScan(result) && navigate('/not-linked');
            }

            setFinderColor('rgba(0, 128, 0, 0.5)');
            return;
        }

        setFinderColor('rgba(0, 0, 0, 0.5)');
    };

    return (
        <PageWrapper bgColor="#000000">
            <QrReader
                ViewFinder={() => {
                    return <ViewFinder color={finderColor} />;
                }}
                scanDelay={500}
                onResult={handleResult}
                constraints={{
                    facingMode: 'environment',
                }}
                containerStyle={{ height: '100vh', width: '100%' }}
                videoContainerStyle={{
                    position: 'relative',
                    height: '100vh',
                    width: '100%',
                    padding: 0,
                }}
                videoStyle={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100vh',
                    width: '100%',
                }}
                videoId="qrScan"
            />
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    justifyItems: 'center',
                    width: '100%',
                    height: '110px',
                    bottom: 0,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
            >
                {companyInfo?.name ? (
                    <>
                        <Typography
                            variant="body2"
                            sx={{ fontSize: '.8em', alignSelf: 'center' }}
                        >
                            Linked to
                        </Typography>
                        <StoreIcon
                            sx={{ fontSize: '1.5em', alignSelf: 'center' }}
                        />
                        <Typography
                            variant="body2"
                            sx={{ fontSize: '.8em', alignSelf: 'center' }}
                        >
                            {companyInfo.name}
                        </Typography>
                    </>
                ) : (
                    <>
                        <QrCodeScannerIcon
                            sx={{ fontSize: '1.5em', alignSelf: 'center' }}
                        />
                        <Typography
                            variant="body2"
                            sx={{ fontSize: '.8em', alignSelf: 'center' }}
                        >
                            Please scan your <b>Partner Identifier QR-Code</b>
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ fontSize: '.8em', alignSelf: 'center' }}
                        >
                            to link this device to your account
                        </Typography>
                    </>
                )}
            </Box>
        </PageWrapper>
    );
};
