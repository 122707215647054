import React, { useLayoutEffect, useRef } from 'react';
import { Box, IconButton } from '@mui/material';
import { CameraRounded } from '@mui/icons-material';
import { PageWrapper } from '../PageWrapper';
import { useInvoice } from '../context/InvoiceContext';
import { useNavigate } from 'react-router-dom';

const TakeInvoicePhoto = () => {
    const { setImage } = useInvoice();
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const navigate = useNavigate();

    const captureImage = () => {
        if (canvasRef.current && videoRef.current) {
            const canvas = canvasRef.current;
            const video = videoRef.current;

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
                .getContext('2d')
                ?.drawImage(video, 0, 0, canvas.width, canvas.height);

            const dataURL = canvas.toDataURL();
            setImage(dataURL);
            navigate('/photo-confirmation');
        }
    };

    useLayoutEffect(() => {
        (async () => {
            const constraints = {
                video: {
                    facingMode: { ideal: 'environment' },
                },
            };

            try {
                const stream = await navigator.mediaDevices.getUserMedia(
                    constraints
                );
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (err) {
                console.error('Error accessing the camera', err);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRef.current]);

    return (
        <PageWrapper bgColor="#000000">
            <video
                ref={videoRef}
                style={{
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100vh',
                    display: 'block',
                    overflow: 'hidden',
                    position: 'absolute',
                }}
                autoPlay
                playsInline
            ></video>
            <canvas
                ref={canvasRef}
                style={{
                    display: 'none',
                }}
            ></canvas>
            <Box
                sx={(theme) => ({
                    position: 'absolute',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    bottom: '100px',
                    [theme.breakpoints.up('md')]: {
                        bottom: '50px',
                    },
                })}
            >
                <IconButton
                    onClick={captureImage}
                    sx={{
                        color: '#FFF',
                    }}
                >
                    <CameraRounded sx={{ width: '2.5em', height: '2.5em' }} />
                </IconButton>
            </Box>
        </PageWrapper>
    );
};

export default TakeInvoicePhoto;
