import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { PageWrapper } from '../PageWrapper';

const GenericFail: React.FC = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/');
    };

    return (
        <PageWrapper>
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#CCCCCC',
                    position: 'relative',
                    color: '#00000',
                    gap: '20px',
                    p: '0 10px',
                }}
            >
                <ErrorOutlineIcon sx={{ fontSize: '100px' }} />
                <Typography variant="body1" sx={{ textTransform: 'uppercase' }}>
                    Something went wrong
                </Typography>
                <Typography variant="body2">
                    Please scan a valid QR code
                </Typography>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleButtonClick}
                    sx={{
                        backgroundColor: '#FFFFFF',
                        margin: '20px 0',
                    }}
                >
                    <Typography
                        sx={{ textTransform: 'uppercase', color: '#000' }}
                    >
                        Scan QR Code
                    </Typography>
                </Button>
            </Box>
        </PageWrapper>
    );
};

export default GenericFail;
